import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Stack,
  Text,
  useBoolean,
  useTheme,
  useToken,
} from "@chakra-ui/react";
import { MomentumLogo } from "donation-form/src/components/form/shared/MomentumLogo";
import { useRouter } from "next/router";
import { urls } from "src/constants";
import { useAuth } from "src/hooks/useAuth";
import { LogOutIcon } from "src/components/shared/icons/LogOutIcon";
import { useEffect, useState } from "react";
import { spacesFixed } from "src/theme/variables";
import { MobileMenu } from "./MobileMenu";

export function NavBar() {
  const theme = useTheme();
  const auth = useAuth();
  const router = useRouter();

  const [isHover, setIsHover] = useState(false);
  const inFocus = () => setIsHover(!isHover);
  const outOfFocus = () => setIsHover(false);

  const [isCompMounted, setIsCompMounted] = useBoolean();
  useEffect(setIsCompMounted.on, []);

  const momentum900 = useToken("colors", "momentum.900");
  const yellow600 = useToken("colors", "yellow.400");

  return (
    <Box position="sticky" top={0} bg={theme.colors.white} zIndex="sticky">
      <Flex
        py={{ base: 4, xl: 3.5 }}
        px={spacesFixed.containerX}
        borderColor="momentum.grey.400"
        borderBottomWidth="1px"
        justifyContent="space-between"
        alignItems="center"
        bg={
          auth.isFormHijacked && isCompMounted
            ? `
          repeating-linear-gradient(
            135deg,
            ${momentum900},
            ${momentum900} 10px,
            ${yellow600} 10px,
            ${yellow600} 20px
          )
        `
            : ""
        }
      >
        {auth.isFormHijacked && isCompMounted ? (
          <Box />
        ) : (
          <MomentumLogo width={184} height={40} color="#2B3070" />
        )}

        {auth.isAuthenticated && isCompMounted && !auth.isDonorPortal && (
          <Menu>
            {/* TODO: fix a problem in a console */}
            <MenuButton
              as={Button}
              variant="outline"
              size="xs"
              fontSize={["sm", null, "md"]}
              height={[9, null, 10]}
              px={[2, null, 4]}
              rightIcon={
                <ChevronDownIcon
                  color="momentum.900"
                  width={theme.space.md2}
                  height={theme.space.md2}
                  ml={theme.space.xs}
                  mt="1px"
                />
              }
            >
              Account
            </MenuButton>

            <MenuList p={0} color="momentum.900">
              <Text
                pl={theme.space.md2}
                pr={theme.space.xl}
                py={0}
                mt={theme.space.md2}
                fontSize="sm"
              >
                My Account
              </Text>

              <Text
                pl={theme.space.md2}
                pr={theme.space.xl}
                pt={theme.space.xs}
                pb={theme.space.md2}
                fontSize="xs"
                letterSpacing="0.25px"
                opacity={0.5}
              >
                {auth?.user?.email}
              </Text>

              <MenuDivider m={0} />

              <Stack
                direction="row"
                mt={theme.space.md2}
                mb={theme.space.md2}
                ml={theme.space.md2}
              >
                <Button
                  onClick={async () => {
                    let redirectUrl: string = urls.account.login;
                    if (router.pathname.startsWith("/charity")) {
                      redirectUrl = urls.charity.login;
                    } else if (router.pathname.startsWith("/donor")) {
                      redirectUrl = urls.donor.login;
                    }
                    await router.push(redirectUrl);
                    auth.logout();
                  }}
                  onMouseOver={inFocus}
                  onMouseOut={outOfFocus}
                  leftIcon={<LogOutIcon state={isHover} width={16} height={16} />}
                  iconSpacing={2.5}
                  variant="none"
                  size="none"
                  pb="1px"
                >
                  Log Out
                </Button>
              </Stack>
            </MenuList>
          </Menu>
        )}
        {auth.isAuthenticated && isCompMounted && auth.isDonorPortal && (
          <Box
            d={{
              base: "flex",
              [theme.config.donorNavBarBreak]: "none",
            }}
            justifyContent="flex-end"
          >
            <MobileMenu />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
