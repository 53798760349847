export function LogOutIcon(props: { state: boolean; width?: number; height?: number }) {
  const color = props.state ? "#4050c9" : "#0F1D58";

  return (
    <svg
      width={props.width ?? "16"}
      height={props.height ?? "16"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.43066 1.75L2.55934 1.75C1.64302 1.75 0.900202 2.49282 0.900202 3.40914L0.900202 12.5909C0.900202 13.5072 1.64303 14.25 2.55934 14.25L6.43064 14.25"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 8.03137H15.5M15.5 8.03137L12.0819 4.25M15.5 8.03137L12.1386 11.75"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
