export const navbarHeight = "69px";

export const spaces = {
  xs: 1, // 13 - xs2
  sm: 2, // 16 - xs
  formLabelMb: 3, // 7
  boxPadding: 4, // 11
  md: 5, // 52
  md2: 6, // 18
  lg: 7, // 14
  xl: 8, // 9 - reuse
  xl2: 10, // 4 - no reuse
  xl3: 12, // 9 - some reuse
  xl4: 20, // 1 - no reuse
};

export const spacesFixed = {
  md: 5,
  xl: [10],
  donorPortalContainerY: { base: 5, xl: 12 },
  containerX: { base: 4, sm: 4, md: 8, xl: 20 },
  containerY: 5,
};

export const colorScheme = "momentum";
export const momentumColors = {
  25: "#F1F2FE",
  50: "#F0F1FF",
  100: "#F0F1FF",
  300: "#7381ea",
  400: "#7381ea",
  500: "#5d6ad1",
  600: "#3f4fc9",
  700: "#3847c3",
  800: "#181E70",
  900: "#0f1d58",
  "primary-pale": "#5d6ad199",
  "secondary-hover": "#091237",
  "secondary-active": "#080f2c",
  "primary-light": "#b3bcff40",
  "primary-light-hover": "#8d9aff40",
  "primary-light-active": "#808fff40",
};
