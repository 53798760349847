export namespace strings {
  export const supportEmail = "support@givemomentum.com";
}

export const bookUs = "mailto:charities@givemomentum.com?subject=Create custom giving rule";

export namespace messages {
  export const tryAgain = `, please try again or contact support at ${strings.supportEmail}`;
  export const errorOccurred = `An error occurred${messages.tryAgain}`;
  export const savingFailed = `Saving failed${messages.tryAgain}`;
}

export const titlePostfix = " - Momentum";
export const errorOccurred = `An error occurred${messages.tryAgain}`;

export namespace nextjs {
  export const isSSR = typeof window === "undefined";
  export const isBrowser = !nextjs.isSSR;
}

export namespace config {
  export const debounce = 3000;
  export const debounceFast = 800;
}

export namespace urls {
  export enum account {
    login = "/account/login",
    signup = "/account/signup",
    profile = "/account/profile",
    setPassword = "/account/set-password",
  }
  export enum form {
    editor = "/form/editor",
    review = "/form/review",
    publish = "/form/publish",
  }
  export enum charity {
    login = "/charity/login",
    dashboard = "/charity/dashboard",
  }
  export enum donor {
    login = "/donor/login",
    pledges = "/donor/pledges",
    history = "/donor/history",
    account = "/donor/account",
    accountPayment = "/donor/account/payment",
  }

  export const isCurrentPage = (url: string) => window.location.pathname === url;
}
