import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { cssVar } from "@chakra-ui/theme-tools";
import { TabsStyle } from "./components/TabsStyle";
import { AccordionStyle } from "./components/AccordionStyle";
import { ButtonStyle } from "./components/ButtonStyle";
import { HeadingStyle } from "./components/HeadingStyle";
import { LinkStyle } from "./components/LinkStyle";
import { momentumColors, colorScheme, spaces } from "./variables";

export const theme: Theme | any = extendTheme(
  {
    sizes: {
      containers: {
        xs: "100%",
        md: "502px",
        lg: "1058px",
        xl: "1280px",
        xl2: "1468px",
      },
    },
    fonts: {
      heading: "Mulish, Arial",
      body: "Mulish, Arial",
    },
    config: {
      colorScheme,
      donorNavBarBreak: "xl",
    },
    colors: {
      transparent: "transparent",
      black: "#000",
      white: "#fff",
      momentum: {
        ...momentumColors,

        grey: {
          50: "#fafafa",
          100: "#f5f5f5",
          200: "#ebebeb",
          300: "#e2e2e2",
          400: "#dadada",
          500: "#a5a5a5",
          600: "#726f6e",
          700: "#686564",
          800: "#5e5b5a",
          900: "#4A4A4A",
        },
      },
    },
    space: spaces,
    textStyles: {
      text: {
        fontSize: "1rem",
        fontWeight: "normal",
        lineHeight: 1.38,
      },
      label: {
        fontSize: "0.875rem",
        fontWeight: "semibold",
        lineHeight: 1.29,
      },
      hints: {
        fontSize: "0.75rem",
        fontWeight: "normal",
        lineHeight: 1.24,
      },
    },
    components: {
      Heading: HeadingStyle,
      Button: ButtonStyle,
      Link: LinkStyle,
      Accordion: AccordionStyle,
      Tabs: TabsStyle,
      Switch: {
        sizes: {
          lg: {
            container: {
              [cssVar("switch-track-width").variable]: "2.75rem",
              [cssVar("switch-track-height").variable]: "1.25rem",
              lineHeight: 1,
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          color: "momentum.900",
          fontWeight: "semibold",
          fontSize: "sm",
          lineHeight: 1.29,
        },
      },
    },
    styles: {
      // todo remove .chakra-fade, it must not be here
      global: {
        "html, body, #__next, .chakra-fade": {
          height: "100%",
        },
        ".grecaptcha-badge": {
          display: "none !important",
        },
        ".chakra-fade": {
          display: "flex",
          flexDirection: "column",
        },
        ".react-colorful": {
          ".react-colorful__saturation": {
            borderRadius: 0,
          },
        },
        ".aside-panel::-webkit-scrollbar": {
          width: { base: "20px", xl: "38px" },
        },
        ".aside-panel::-webkit-scrollbar-thumb": {
          backgroundColor: "#d6dee1",
          border: { base: "7px solid rgba(0, 0, 0, 0)", xl: "15px solid rgba(0, 0, 0, 0)" },
          borderRadius: "1.5rem",
          backgroundClip: "padding-box",
        },
        ".aside-panel::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#a8bbbf",
        },
        ".rs-picker-toggle": {
          "--rs-border-primary": "#babfc7",
        },
        ".rs-picker-toggle-caret": {
          width: "15px",
          _hover: {
            color: "#646fc1",
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme }),
);
