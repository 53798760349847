import { StyleConfig } from "@chakra-ui/theme-tools";
import { spaces } from "../variables";

const radius = "4px";

export const TabsStyle: StyleConfig = {
  variants: {
    "enclosed-colored": {
      tab: {
        bg: "momentum.25",
        color: "momentum.primary-pale",
        borderRadius: radius,
        border: 0,
        _first: {
          borderRightRadius: 0,
        },
        _last: {
          borderLeftRadius: 0,
        },
        _selected: {
          bg: "momentum.500",
          borderColor: "inherit",
          borderTopColor: "currentColor",
          borderBottomColor: "transparent",
          borderRadius: `${radius} !important`,
          color: "white",
        },
      },
      tablist: {
        borderBottom: 0,
        bg: "momentum.25",
        borderRadius: radius,
        w: "fit-content",
      },
      tabpanel: {
        px: 0,
        pt: spaces.formLabelMb + 0.5,
      },
    },
  },
};
