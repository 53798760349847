import { useBoolean } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect } from "react";
import { LoadingScreen } from "src/components/shared/LoadingScreen";

type BooleanToggle = ReturnType<typeof useBoolean>[1];

const LoadingScreenContext = createContext(
  {} as {
    hide: () => void;
    show: () => void;
    isLoading: boolean;
    setIsLoading: BooleanToggle;
  },
);

export function LoadingScreenProvider(props: { children: any }) {
  const [isLoading, setIsLoading] = useBoolean(false);

  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeStart", setIsLoading.on);
    router.events.on("routeChangeComplete", setIsLoading.off);
    router.events.on("routeChangeError", setIsLoading.off);
  }, [router]);

  return (
    <LoadingScreenContext.Provider
      value={{
        hide: setIsLoading.off,
        show: setIsLoading.on,
        isLoading,
        setIsLoading,
      }}
    >
      <LoadingScreen isVisible={isLoading}>{props.children}</LoadingScreen>
    </LoadingScreenContext.Provider>
  );
}

export const useLoadingScreen = () => useContext(LoadingScreenContext);
