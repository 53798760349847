import { Box, Fade, Flex, Text, useBoolean, useTheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { createContext, useContext, useState } from "react";

const NetworkErrorNotifierContext = createContext(
  {} as {
    hide: () => void;
    show: (lastSavedTime: Date | null) => void;
  },
);

export function NetworkErrorNotifierProvider(props: { children: any }) {
  const theme: Theme | any = useTheme();
  const [isVisible, setIsVisible] = useBoolean(false);
  const [lastSavedTime, setLastSavedTime] = useState<Date | null>(null);

  let lastSavedMinAgo = 0;
  if (lastSavedTime) {
    const lastSavedSecAgo = Math.floor((Number(new Date()) - Number(lastSavedTime)) / 1000);
    lastSavedMinAgo = lastSavedSecAgo / 60;
  }

  return (
    <NetworkErrorNotifierContext.Provider
      value={{
        hide: setIsVisible.off,
        show: (lastSavedTime: Date | null) => {
          setLastSavedTime(lastSavedTime);
          setIsVisible.on();
        },
      }}
    >
      <Fade in={isVisible} unmountOnExit style={{ height: "100%" }}>
        <Flex
          position="fixed"
          top={0}
          w="100%"
          zIndex="banner"
          h={14}
          bg="red.600"
          color="white"
          alignItems="center"
          justifyContent="center"
        >
          Your changes could not be saved, please check your internet connection
          {lastSavedTime && <Text>Last saved {lastSavedMinAgo} min ago</Text>}
        </Flex>
      </Fade>
      <Box h="100%">{props.children}</Box>
    </NetworkErrorNotifierContext.Provider>
  );
}

export const useNetworkErrorNotifier = () => useContext(NetworkErrorNotifierContext);
