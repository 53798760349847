import { useState } from "react";
import { AxiosService } from "src/axios";
import { Sentry } from "src/sentry";

export function usePaymentMethods() {
  const [list, setList] = useState<PaymentMethod[]>([]);
  const [current, setCurrent] = useState<PaymentMethod | null>(null);

  async function fetchAll() {
    try {
      const res = await AxiosService.get("/payment_methods/");
      const methods: PaymentMethod[] = res.data.map(deserializePaymentMethod);
      setList(methods);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  async function fetch() {
    try {
      const res = await AxiosService.get("/payment_methods/default");
      setCurrent(deserializePaymentMethod(res.data));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  async function update(stripeToken: string) {
    await AxiosService.post(`/payment_methods/`, {
      stripe_card_token: stripeToken,
      set_default: true,
    });
    await fetch();
  }

  /* eslint-disable object-shorthand */
  return {
    isEmpty: current === null,
    list: list,
    current: current,
    fetch: fetch,
    update: update,
  };
}

function deserializePaymentMethod(method: PaymentMethodRaw): PaymentMethod {
  let methodFlattened = Object.assign(method, method.ach) as any as PaymentMethod;
  methodFlattened = Object.assign(methodFlattened, method.card);

  if (method.ach) {
    methodFlattened.type = "ach";
  }
  if (method.card) {
    methodFlattened.type = "card";
  }
  return methodFlattened;
}

interface PaymentMethodRaw {
  stripe_customer_id: string;
  ach?: {
    id: number;
    stripe_id: string;
    status: string;
    last4: string;
    bank_name: string;
  };
  card?: {
    id: number;
    stripe_id: string;
    last4: string;
    wallet_type: string;
    brand:
      | "amex"
      | "cartes_bancaires"
      | "diners_club"
      | "discover"
      | "jcb"
      | "mastercard"
      | "visa"
      | "unionpay";
  };
}

export interface PaymentMethod {
  stripe_customer_id: string;
  type: "ach" | "card";
  id: number;
  stripe_id: string;

  ach?: {
    status: string;
    last4: string;
    bank_name: string;
  };
  card?: {
    last4: string;
    wallet_type: string;
    brand:
      | "amex"
      | "cartes_bancaires"
      | "diners_club"
      | "discover"
      | "jcb"
      | "mastercard"
      | "visa"
      | "unionpay";
  };
}
