import { Icon } from "@chakra-ui/icons";
import { Box, Container, Flex, Link, Text, useTheme } from "@chakra-ui/react";
import Head from "next/head";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { MdOutlineAccountCircle, MdOutlineHistory } from "react-icons/md";
import { GoSync } from "react-icons/go";
import { titlePostfix, urls } from "src/constants";
import { navbarHeight, spacesFixed } from "src/theme/variables";

export function DonorContainer(props: { children: any; pageTitle: string }) {
  const theme = useTheme();

  return (
    <Flex flexDir="row" h="100%" flexGrow="1" px={spacesFixed.containerX}>
      <Head>
        <title>
          {props.pageTitle}
          {titlePostfix}
        </title>
      </Head>

      <Flex
        h="100%"
        grow={1}
        flexDir="column"
        minH="100%"
        maxW="320px"
        minW="250px"
        py={spacesFixed.donorPortalContainerY}
        w="25%"
        fontSize="lg"
        borderRight="1px solid black"
        borderColor="gray.300"
        d={{
          base: "none",
          [theme.config.donorNavBarBreak]: "flex",
        }}
      >
        <Flex
          flexDir="column"
          alignSelf="flex-start"
          position="sticky"
          top={navbarHeight}
          h="100%"
        >
          <NavLinks />
        </Flex>
      </Flex>

      <Container
        m={0}
        maxW="container.lg"
        px={{ ...spacesFixed.containerX, base: 0, sm: 0, md: 0, xl: 14, "2xl": 20 }}
        pt={spacesFixed.donorPortalContainerY}
      >
        {props.children}
      </Container>
    </Flex>
  );
}

export function NavLinks() {
  return (
    <Box>
      <NavLink url={urls.donor.pledges} icon={GoSync} label="Recurring" />
      <NavLink url={urls.donor.history} icon={MdOutlineHistory} label="History" />
      <NavLink url={urls.donor.account} icon={MdOutlineAccountCircle} label="Account" />
    </Box>
  );
}

function NavLink(props: { url: string; icon: any; label: string }) {
  const router = useRouter();
  const isCurrent = router.pathname.includes(props.url);
  return (
    <NextLink href={props.url} passHref>
      <Link
        d="flex"
        alignItems="center"
        mt={2}
        color={isCurrent ? "momentum.500" : "gray.500"}
        fontSize="xl"
        _hover={{ textTransform: "none", color: "momentum.500" }}
        _focus={{ color: "momentum.600" }}
        _active={{ color: "momentum.600" }}
      >
        <Icon as={props.icon} boxSize="21px" />
        <Text ml={2.5}>{props.label}</Text>
      </Link>
    </NextLink>
  );
}
