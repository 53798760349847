import { StyleConfig } from "@chakra-ui/theme-tools";
import { spaces } from "../variables";

export const AccordionStyle: StyleConfig = {
  variants: {
    "form-sections": {
      container: {
        borderTop: "none",
      },
      button: {
        py: spaces.md2,
        ps: { base: spaces.md2, xl: spaces.xl4 },
        pe: { base: spaces.sm, xl: spaces.md2 },
        justifyContent: "space-between",
        fontSize: "xl",
        fontWeight: "bold",
        color: "momentum.900",
      },
      panel: {
        px: { base: spaces.md2, xl: spaces.xl4 },
        pr: { base: spaces.sm, xl: spaces.md2 },
      },
    },
  },
};
