import { AlertStatus } from "@chakra-ui/alert/src/alert";
import { Alert, AlertIcon, Box, useTheme } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

const NotificationContext = createContext(
  {} as {
    render: () => JSX.Element;
    add(status: AlertStatus, text: string);
  },
);

export function NotificationProvider(props: { children: any }) {
  const [list, setList] = useState([] as JSX.Element[]);
  const theme = useTheme();

  function add(status: AlertStatus, text: string) {
    const alert = (
      <Alert key={text} status={status} variant="left-accent">
        <AlertIcon />
        {text}
      </Alert>
    );
    setList([...list, alert]);
  }

  function render() {
    return <Box mb={theme.space.xl}>{list}</Box>;
  }

  return (
    <NotificationContext.Provider value={{ render, add }}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationContext);
