import { useState } from "react";
import { AxiosService } from "src/axios";
import { Sentry } from "src/sentry";
import { formatCurrency } from "src/formatting";

export interface FastStats {
  amount_pending: number;
  donated_this_month: number;
  donated_all_time: number;
}

export function useFastStats() {
  const [data, setData] = useState<FastStats | null>(null);

  async function fetch(userId: number) {
    try {
      const data = await AxiosService.get(`/faststats/users/${userId}`);

      const formattedData: FastStats = {
        amount_pending: 0,
        donated_this_month: 0,
        donated_all_time: 0,
      };
      for (const [key, value] of Object.entries(data?.data)) {
        formattedData[key] = formatCurrency(value as number);
      }
      setData(formattedData);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  /* eslint-disable object-shorthand */
  return {
    fetch: fetch,
    data: data,
  };
}
