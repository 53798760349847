import { StyleConfig } from "@chakra-ui/theme-tools";

export const HeadingStyle: StyleConfig = {
  baseStyle: {
    color: "momentum.900",
    fontSize: "heading.sizes.md",
    lineHeight: 0.74,
  },
  variants: {
    h1: {
      fontSize: "2rem",
      lineHeight: 1.34,
      fontWeight: "semibold",
      letterSpacing: "0.5px",
    },
    h2: {
      fontSize: "1.25rem",
      lineHeight: ["none", null, 0.74],
      fontWeight: "semibold",
      letterSpacing: "0.5px",
    },
    h3: {
      fontSize: "1rem",
      lineHeight: ["none", null, 1.25],
      fontWeight: "bold",
    },
  },
};
