import { StyleConfig } from "@chakra-ui/theme-tools";

export const LinkStyle: StyleConfig = {
  variants: {
    primary: {
      color: "momentum.500",
      _hover: {
        color: "momentum.600",
      },
      _focus: {
        color: "momentum.700",
      },
      _active: {
        color: "momentum.700",
      },
    },
    secondary: {
      px: 1,
      textDecoration: "underline",
      color: "momentum.900",
      _hover: {
        color: "momentum.500",
      },
    },
    external: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontWeight: "bold",
      color: "momentum.500",
      _hover: {
        color: "momentum.600",
        textDecoration: "none",
      },
    },
  },
};
