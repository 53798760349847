import { Data } from "dataclass";
import { useState } from "react";
import { AxiosService } from "src/axios";
import { formatCurrency } from "src/formatting";
import { Sentry } from "src/sentry";

export function usePledges() {
  const [list, setList] = useState<Pledge[]>([]);

  async function fetchAll() {
    try {
      const res = await AxiosService.get("/pledge/");
      const pledgesRecurringRaw = res.data.filter((pledge) => pledge.kind !== "once");
      setList(pledgesRecurringRaw.map((pledge) => Pledge.create(pledge)));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  async function edit(
    pledge: Pledge,
    data: { fixed_dollars?: number; is_active?: boolean; limit_dollars?: number | null },
  ) {
    await AxiosService.patch(`/pledge/${pledge.unique_id}`, data);
    await fetchAll();
  }

  async function activate(pledge: Pledge) {
    await AxiosService.patch(`/pledge/${pledge.unique_id}`, { is_active: true });
    await fetchAll();
  }

  /* eslint-disable object-shorthand */
  return {
    list: list,
    fetchAll: fetchAll,
    activate: activate,
    edit: edit,
  };
}

export class Pledge extends Data {
  rule_id!: number;
  unique_id!: string;
  title!: string;
  condition!: string;
  type!: "MANUAL" | "SCHEDULE" | "SPENDING";
  kind!: "impact_based" | "event_based" | "monthly" | "once" | "schedule";
  recipient_name!: string;
  fund_id?: number;
  charity_id?: number;
  is_active!: boolean;
  amount_type!: "fixed" | "percent" | "round";
  fixed_dollars?: number;
  percent?: number;
  round_dollars?: number;
  limit_dollars?: number;
  cron?: string;
  created_at!: string;
  withdrawal_next_at!: string;

  get amount(): number {
    if (this.amount_type === "fixed") {
      return Number(this.fixed_dollars);
    }
    if (this.amount_type === "percent") {
      return Number(this.percent);
    }
    if (this.amount_type === "round") {
      return Number(this.round_dollars);
    }
    throw Error("Pledge missing amount");
  }

  get amountFieldName(): string {
    if (this.amount_type === "fixed") {
      return "fixed_dollars";
    }
    if (this.amount_type === "percent") {
      return "percent";
    }
    if (this.amount_type === "round") {
      return "round_dollars";
    }
    throw Error();
  }

  get amountSign(): string {
    if (this.amount_type === "percent") {
      return "%";
    }
    return "$";
  }

  get amountStr(): string {
    if (this.amount_type === "fixed") {
      return formatCurrency(this.amount!).replace(/\.00$/, "");
    }
    if (this.amount_type === "percent") {
      return `${this.amount}%`;
    }
    if (this.amount_type === "round") {
      return `Round up to ${formatCurrency(this.amount!).replace(/\.00$/, "")}`;
    }
    throw Error();
  }
}
