import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLinks } from "src/components/donor/DonorContainer";

// NOTE: this is largely duplicated from campaign-template repo
export function MobileMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef() as any;
  const theme = useTheme();

  return (
    <>
      <Button
        as={IconButton}
        onClick={onOpen}
        ref={btnRef}
        aria-label="Menu"
        variant="none"
        icon={<GiHamburgerMenu />}
        px={0}
        colorScheme="grey"
        _hover={{ backgroundColor: theme.colors.momentum["100"] }}
      />

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef!}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt="6px" />
          <DrawerHeader color="black">Menu</DrawerHeader>
          <DrawerBody pt={0}>
            <NavLinks />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
