import { Box, Fade, keyframes, useTheme, useToken } from "@chakra-ui/react";
import { NavBar } from "src/components/shared/NavBar";

export function LoadingScreen(props: { isVisible: boolean; children: any }) {
  const theme = useTheme();

  const spinKeyframes = keyframes`
    100% {
      transform: rotate(360deg);
    }
  `;
  const spinAnimation = `${spinKeyframes} 0.8s linear infinite`;
  const duration = 0.3;
  const ease = [0.4, 0, 1, 1];
  const primary = useToken("colors", "momentum.500");

  return (
    <Box h="100%">
      <Fade
        in={props.isVisible}
        unmountOnExit
        transition={{
          enter: { duration: 0.1 },
          exit: {
            duration,
            ease,
          },
        }}
        style={{
          height: "100%",
        }}
      >
        <NavBar />
        <Box
          pos="absolute"
          w="100%"
          h="100vh"
          justifyContent="center"
          alignItems="center"
          d="flex"
          bg="white"
        >
          <Box
            d="flex"
            pos="relative"
            alignItems="center"
            justifyContent="center"
            animation={spinAnimation}
            w="50px"
            h="50px"
            bg={`linear-gradient(180deg, ${primary} 0%, rgba(0, 0, 0, 0) 35%)`}
            borderRadius="50%"
            _after={{
              content: `" "`,
              w: "calc(100% - 6px)",
              h: "calc(100% - 6px)",
              borderRadius: "50%",
              bg: "white",
            }}
          />
        </Box>
      </Fade>

      <Fade
        in={!props.isVisible}
        transition={{
          enter: {
            duration,
            ease,
          },
          exit: { duration: 0.4 },
        }}
        style={{
          height: "100%",
        }}
      >
        {props.children}
      </Fade>
    </Box>
  );
}
