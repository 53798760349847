import { StyleConfig } from "@chakra-ui/theme-tools";

export const ButtonStyle: StyleConfig = {
  baseStyle: {
    fontWeight: "bold",
    lineHeight: "shorter",
    borderRadius: "5px",
    _disabled: {
      background: "momentum.grey.300",
      color: "momentum.grey.500",
      cursor: "not-allowed",
    },
    _hover: {
      _disabled: {
        background: "momentum.grey.300",
        color: "momentum.grey.500",
        cursor: "not-allowed",
      },
    },
  },
  sizes: {
    none: {
      fontSize: "sm",
      height: 4,
    },
    xs: {
      fontSize: "xs",
      height: [6, null, 8],
      textTransform: "none",
    },
    sm: {
      fontSize: "sm",
      height: [9, null, 10],
      textTransform: "none",
    },
    md: {
      fontSize: "md",
      height: 12,
    },
    lg: {
      fontSize: "lg",
      height: 12,
    },
  },
  variants: {
    outline: {
      border: "1px solid",
      color: "momentum.900",
      borderColor: "momentum.grey.300",
      background: "white",
      _hover: {
        background: "momentum.grey.100",
      },
    },
    solid: {
      color: "white",
      textTransform: "uppercase",
    },
    none: {
      padding: 0,
      background: "transparent",
      border: "none",
      color: "momentum.900",
      _hover: {
        color: "momentum.500",
      },
    },
    link: {
      color: "momentum.500",
      fontWeight: "semibold",
      textDecoration: "underline",
      _hover: {
        color: "momentum.600",
      },
      _focus: {
        color: "momentum.700",
        boxShadow: "none",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};
