import { useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import { AxiosService } from "src/axios";
import { Sentry } from "src/sentry";

export interface Withdrawal {
  id: number;
  total_amount: string;
  date: string;
  status: "successful" | string;
  payment_method: string;
  type: "ach" | "card" | null;
  brand: string | null;
  last4: string | null;
  bank_name: string | null;
  charity_details: [
    {
      charity_name: string;
      donation_amount: string;
    },
  ];
}

export function useWithdrawals() {
  const [list, setList] = useState<Withdrawal[]>([]);
  const [isLoading, setIsLoading] = useBoolean(true);

  async function fetchAll(userId: number) {
    try {
      const data = await AxiosService.get(`/payments/history/user/${userId}`);
      setList(data.data);
    } catch (error: any) {
      Sentry.captureException(error);
    }
    setIsLoading.off();
  }

  /* eslint-disable object-shorthand */
  return {
    isLoading: isLoading,
    isEmpty: list.length === 0,
    fetchAll: fetchAll,
    list: list,
  };
}
