import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import type { AppProps } from "next/app";
import { env } from "src/env";
import { AuthProvider } from "src/hooks/useAuth";
import { NavBar } from "src/components/shared/NavBar";
import { LoadingScreenProvider } from "src/hooks/useLoadingScreen";
import { NetworkErrorNotifierProvider } from "src/hooks/useNetworkErrorNotifier";
import { NotificationProvider } from "src/hooks/useNotifications";
import { Sentry } from "src/sentry";
import { theme } from "src/theme";
import ReactGA from "react-ga4";

export default function MomentumApp({ Component, pageProps }: AppProps) {
  if (env.isBrowser && env.isProd) {
    LogRocket.init("alcw3f/portal-ux06u");
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
    setupLogRocketReact(LogRocket);
    ReactGA.initialize("G-NZ8M2B9L5X");
  }

  return (
    <ChakraProvider theme={theme}>
      <LoadingScreenProvider>
        <NetworkErrorNotifierProvider>
          <NotificationProvider>
            <AuthProvider>
              <Box h="100%">
                <NavBar />

                <Component {...pageProps} />
              </Box>
            </AuthProvider>
          </NotificationProvider>
        </NetworkErrorNotifierProvider>
      </LoadingScreenProvider>
    </ChakraProvider>
  );
}
