import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction } from "react";
import { urls } from "src/constants";
import { useAuth, User } from "src/hooks/useAuth";
import { useInit } from "src/hooks/useInit";
import { useLoadingScreen } from "src/hooks/useLoadingScreen";
import { useNotifications } from "src/hooks/useNotifications";
import { Sentry } from "src/sentry";

export function useRenderWithFormData(args: {
  component: Function;
  url: urls.account | urls.form;
  setJsxOutput: Dispatch<SetStateAction<JSX.Element>>;
}) {
  const hooks = {
    auth: useAuth(),
    router: useRouter(),
    notifications: useNotifications(),
    loader: useLoadingScreen(),
  };

  useInit(async () => {
    hooks.loader.show();

    const isFormHijacked = getUrlParam("form_pk") !== undefined;
    if (isFormHijacked) {
      const formData = await hooks.auth.hijackForm(
        getUrlParam("form_pk")!,
        getUrlParam("token")!,
      );
      args.setJsxOutput(<args.component {...formData} />);
      await hooks.router.push(args.url);
      hooks.loader.hide();
      return;
    }

    const loginToken = getUrlParam("token");
    if (!hooks.auth.isAuthenticated && !loginToken) {
      hooks.notifications.add("info", "Please login to continue");
      await hooks.router.push(urls.account.login);
    }

    let user: User | null = null;
    if (loginToken) {
      user = await hooks.auth.loginWithToken(loginToken);
    }

    try {
      const formData = await hooks.auth.createOrFetchDonationForm(user);

      args.setJsxOutput(<args.component {...formData} />);
      hooks.loader.hide();
    } catch (error: any) {
      if (error?.code === "no_charity") {
        hooks.notifications.add("info", "Please complete your profile");
        await hooks.router.push(urls.account.profile);
      } else if (error?.code === "invalid_user") {
        hooks.notifications.add("info", "Please login to continue");
        await hooks.router.push(urls.account.login);
      } else {
        Sentry.captureException(error);
      }
    }
  });
}

export function getUrlParam(name: string): string | undefined {
  let queryParamsRaw;
  if (typeof window !== "undefined") {
    queryParamsRaw = new URLSearchParams(window.location.search);
  } else {
    queryParamsRaw = new URLSearchParams();
  }
  const queryParams = Object.fromEntries(queryParamsRaw.entries());
  return queryParams[name];
}
